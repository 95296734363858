import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Wizard } from '@shared/ui/misc/Wizard';
import Initial from './Initial';
import Edit from './Edit';
import Save from './Save';
import ConfirmDelete from './ConfirmDelete';
import Warning from './Warning';
const ProfileAvatarEditorComponent = ({ initialStep = undefined, setValue, getValues, watch, }, outerRef) => (_jsxs(Wizard, { initialStep: initialStep, children: [_jsx(Initial, { setValue: setValue, getValues: getValues, watch: watch, ref: outerRef }), _jsx(Edit, { setValue: setValue, getValues: getValues, watch: watch, ref: outerRef }), _jsx(Save, { setValue: setValue, getValues: getValues, watch: watch, ref: outerRef }), _jsx(Warning, { setValue: setValue, getValues: getValues, watch: watch, ref: outerRef }), _jsx(ConfirmDelete, { setValue: setValue, getValues: getValues, watch: watch, ref: outerRef })] }));
const ProfileAvatarEditor = forwardRef(ProfileAvatarEditorComponent);
export default ProfileAvatarEditor;
